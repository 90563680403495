import { createContext } from 'react';
import { blankConfig, Config } from '../domain/Config';

export interface AppContextProps {
    config: Config;
    setConfig: (config: Config) => void;
}

const appContext = createContext<AppContextProps>({
    config: blankConfig,
    setConfig: () => {
        //do nothing
    },
});

export default appContext;
