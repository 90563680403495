export type UserInfo = {
    readonly username: string;
    id?: string;
    email: string;
};

export const blankUserInfo: UserInfo = {
    username: '',
    id: undefined,
    email: '',
};
