import moment, { Moment } from 'moment';

import { DateType } from '../domain/Date';

export const getMomentFromDateMap = (dob: DateType): Moment => {
    return moment(`${dob.year}-${dob.month}-${dob.day}`, 'YYYY-MM-DD');
};

export const getMomentForToday = (): Moment => {
    return moment(new Date(), 'YYYY-MM-DD');
};

export const isMoreThan16YearsOld = (date: DateType): boolean => {
    const sixteenYearsAgo = getMomentForToday().subtract(16, 'years');
    const dobToCheck = getMomentFromDateMap(date);
    return !dobToCheck.isAfter(sixteenYearsAgo);
};
