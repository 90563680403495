import axios from 'axios';

axios.defaults.timeout = 6000;
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';

export const BASE_URL = `/registration`;

export function apiUrl(endpoint: string, version = 'v1'): string {
    return `${BASE_URL}/api/${version}/${endpoint}`;
}
