import React, { FunctionComponent, KeyboardEvent, ReactElement, useState } from 'react';
import styles from './passwordInput.module.scss';
import classnames from 'classnames';
import ValidationList from '../validation/ValidationList';

interface PasswordProps {
    password: string;
    error: string | undefined;
    onChange: (password: string) => void;
    handleSubmit: () => void;
}

const PasswordInput: FunctionComponent<PasswordProps> = ({ password, onChange, error, handleSubmit }): ReactElement => {
    const [visibility, setVisibility] = useState<boolean>(false);

    const setPasswordVisibility = (newVisibility: boolean): void => {
        setVisibility(newVisibility);
    };

    const onKeyPress = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
        if (event.key === 'Enter') {
            return handleSubmit();
        }
    };

    return (
        <div>
            <label className={styles.inputLabel} htmlFor='password'>
                Password
            </label>
            <div className={styles.inputHints} id={'password-input-hint-text'}>
                <p className={styles.hint}>This needs to have at least:</p>
                <ValidationList hasErrors={!!error} password={password} />
            </div>
            <div className={classnames({ [styles.errorPasswordInputGroup]: error }, styles.passwordInputGroup)}>
                <ul className={styles.inlineErrors}>
                    {error && (
                        <li key={error} className={styles.inlineErrorMessage}>
                            {error}
                        </li>
                    )}
                </ul>

                <div className={styles.passwordInputField}>
                    <input
                        id='password'
                        autoComplete='new-password'
                        className={classnames({ [styles.errorInputField]: error }, styles.inputField)}
                        aria-describedby={'password-input-hint-text password-requirements'}
                        type={visibility ? 'text' : 'password'}
                        value={password}
                        onChange={(event): void => onChange(event.target.value)}
                        onKeyPress={onKeyPress}
                    />
                    <button
                        className={classnames({ [styles.errorButton]: error }, styles.showHideButton)}
                        onClick={(): void => setPasswordVisibility(!visibility)}
                        data-ga={!visibility ? 'show-password' : 'hide-password'}
                        aria-live={'polite'}
                        aria-label={
                            !visibility
                                ? 'By clicking on this button, text typed in the password field will be displayed and will be spoken by screen readers'
                                : undefined
                        }
                    >
                        {visibility ? 'Hide password' : 'Show password'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PasswordInput;
