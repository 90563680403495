export const JobTitles = [
    'Sales Executive (Motability Specialist)',
    'Business Manager',
    'Sales Manager',
    'Aftersales Advisor (Motability Specialist)',
    'Workshop Controller',
    'Aftersales Manager',
    'Head of Business',
    'Contact Centre Executive',
    'Contact Centre Manager',
    'Group Motability Specialist',
    'Franchise Director',
    'Accountant',
    'Administrative Support',
    'Marketing Manager',
];
