export class ApplicationError extends Error {
    public readonly message: string = 'Unexpected error occurred';
    public readonly name: string = 'ApplicationError';

    public constructor(name: string, message: string) {
        super();

        this.name = name;
        this.message = message;
    }

    public isSystemError(): boolean {
        return false;
    }

    public isServiceUnavailableError(): boolean {
        return false;
    }
}

export class SystemError extends ApplicationError {
    public constructor(statusCode: string) {
        super('SystemError', `Got a ${statusCode}`);
    }

    public isSystemError(): boolean {
        return true;
    }
}

export class ServiceUnavailableError extends ApplicationError {
    public constructor(message: string) {
        super('ServiceUnavailableError', message);
    }

    public isServiceUnavailableError(): boolean {
        return true;
    }
}
