interface Validation {
    label: string;
    analyticsTag: string;
    validate: (pass: string) => boolean;
}

export const validations: Validation[] = [
    {
        label: 'Eight characters',
        analyticsTag: 'password-validation-not-at-least-eight-characters',
        validate: (pass: string): boolean => {
            return pass.length >= 8;
        },
    },
    {
        label: 'One uppercase letter',
        analyticsTag: 'password-validation-not-at-least-one-uppercase-character',

        validate: (pass: string): boolean => {
            return /(?=.*[A-Z])/.test(pass);
        },
    },
    {
        label: 'One lowercase letter',
        analyticsTag: 'password-validation-not-at-least-one-lowercase-character',

        validate: (pass: string): boolean => {
            return /(?=.*[a-z])/.test(pass);
        },
    },
    {
        label: 'One number',
        analyticsTag: 'password-validation-not-at-least-one-number',

        validate: (pass: string): boolean => {
            return /(?=.*[0-9])/.test(pass);
        },
    },
];
