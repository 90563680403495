import React, { ReactElement } from 'react';
import { ServiceErrorMessage } from '@mo/service-error-message';
import styles from './technicalError.module.scss';

export const TechnicalError = (): ReactElement => (
    <ServiceErrorMessage
        visible={true}
        title='A technical error happened'
        className={styles.technicalError}
        hintClassName={styles.technicalErrorHint}
        titleClassName={styles.technicalErrorTitle}
        messageClassName={styles.technicalErrorMessage}
    >
        <>
            Sorry, we’re having some problems creating your password. Please try again in a few moments. If this problem
            persists please call us on <a href={'tel:0300 037 0200'}>0300 037 0200</a>.
        </>
    </ServiceErrorMessage>
);
