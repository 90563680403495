import { useEffect, useState } from 'react';
import { blankConfig, Config } from '../../domain/Config';
import { getConfig } from './configGateway';

export interface GetConfigResponse {
    loading: boolean;
    technicalError: boolean;
    config: Config;
}

const useConfig = (): GetConfigResponse => {
    const [loading, setLoading] = useState(true);
    const [technicalError, setTechnicalError] = useState(false);
    const [config, setConfig] = useState<Config>(blankConfig);

    useEffect(() => {
        const fetch = async (): Promise<void> => {
            try {
                setConfig(await getConfig());
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setTechnicalError(true);
            }
        };

        void fetch();
    }, []);

    return { loading, technicalError, config };
};

export { useConfig };
