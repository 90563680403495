import * as Yup from 'yup';
import { ValidationError } from 'yup';

import { EMAIL_DOMAIN_NOT_ALLOWED, INVALID_EMAIL_FORMAT } from './errors';
import { emailValidation } from '@mo/validation';

interface EmailData {
    email?: string | null;
}

const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
        .test('email', INVALID_EMAIL_FORMAT, function (email): boolean {
            return email !== undefined && !emailValidation.emailFailsSimpleFormatChecks(email);
        })
        .test('email', EMAIL_DOMAIN_NOT_ALLOWED, function (email): boolean {
            return email !== undefined && !domainBlockedList.includes(email.split('@', 2)[1]);
        }),
});

type ErrorType = { [path: string]: string[] };

const mapEmailSchemaErrors = (e: ValidationError): ErrorType =>
    e.inner.reduce((errors: ErrorType, error: ValidationError): ErrorType => {
        if (error.path === undefined) {
            return errors;
        }
        const message = errors[error.path] || [];
        return {
            ...errors,
            [error.path]: message.concat(error.message),
        };
    }, {});

export const validateEmail = (data: EmailData): Promise<EmailData> => {
    return emailValidationSchema.validate(data, { abortEarly: false }).catch((e): EmailData => {
        throw mapEmailSchemaErrors(e);
    });
};

const domainBlockedList: string[] = [
    'gmail.com',
    'outlook.com',
    'outlook.co.uk',
    'yahoo.com',
    'hotmail.com',
    'hotmail.co.uk',
];
