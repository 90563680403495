import * as Yup from 'yup';
import { ValidationError } from 'yup';

import * as Errors from './errors';
import { INVALID_JOB_TITLE } from './errors';
import moment from 'moment';
import { DateType } from '../../../../domain/Date';
import { JOB_TITLE_PLACEHOLDER } from '../../../../domain/PersonalDetails';
import { isMoreThan16YearsOld } from '../../../../common/dateUtils';

const mobileRegexp = /^(07\d{9})?$/;

interface PersonalDetailsValidationData {
    firstName?: string | null;
    lastName?: string | null;
    dob?: DateType;
    phoneNumber?: string | null;
    jobTitle?: string | null;
    tcAccepted?: boolean;
}

interface TransformedPersonalDetailsValidationData {
    firstName?: string | null;
    lastName?: string | null;
    dob?: {
        day?: number;
        month?: number;
        year?: number;
    } | null;
    phoneNumber?: string | null;
    jobTitle?: string | null;
    tcAccepted?: boolean;
}

const validationSchema = Yup.object().shape({
    dob: Yup.object()
        .nullable()
        .test('validDate', Errors.INVALID_DOB, function (dob: DateType | null): boolean {
            if (dob?.day && dob?.month && dob?.year) {
                const date = moment(`${dob.year}-${dob.month}-${dob.day}`, 'YYYY-MM-DD');
                return date.isValid();
            } else {
                return true;
            }
        })
        .test('atLeast16YearsOld', Errors.INVALID_DOB, function (dob: DateType | null): boolean {
            if (dob?.day && dob?.month && dob?.year) {
                return isMoreThan16YearsOld(dob);
            } else {
                return true;
            }
        })
        .shape({
            day: Yup.number()
                .transform((cv, ov): number => (ov === '' ? null : cv))
                .typeError(Errors.INVALID_OR_BLANK_DAY)
                .integer(Errors.INVALID_OR_BLANK_DAY)
                .nullable()
                .positive(Errors.INVALID_OR_BLANK_DAY)
                .max(31, Errors.INVALID_OR_BLANK_DAY)
                .required(Errors.INVALID_OR_BLANK_DAY),
            month: Yup.number()
                .transform((cv, ov): number => (ov === '' ? null : cv))
                .typeError(Errors.INVALID_OR_BLANK_MONTH)
                .integer(Errors.INVALID_OR_BLANK_MONTH)
                .nullable()
                .positive(Errors.INVALID_OR_BLANK_MONTH)
                .max(12, Errors.INVALID_OR_BLANK_MONTH)
                .required(Errors.INVALID_OR_BLANK_MONTH),
            year: Yup.number()
                .transform((cv, ov): number => (ov === '' ? null : cv))
                .typeError(Errors.INVALID_OR_BLANK_YEAR)
                .integer(Errors.INVALID_OR_BLANK_YEAR)
                .nullable()
                .min(1900, Errors.INVALID_OR_BLANK_YEAR)
                .required(Errors.INVALID_OR_BLANK_YEAR),
        }),
    firstName: Yup.string().nullable().required(Errors.INVALID_FIRST_NAME),
    lastName: Yup.string().nullable().required(Errors.INVALID_LAST_NAME),
    phoneNumber: Yup.string().nullable().matches(mobileRegexp, Errors.INVALID_MOBILE_PHONE),
    tcAccepted: Yup.boolean().oneOf([true], Errors.TC_NOT_ACCEPTED),
    jobTitle: Yup.string()
        .nullable()
        .test('jobTitle', INVALID_JOB_TITLE, function (selectedJobTitle): boolean {
            return selectedJobTitle !== JOB_TITLE_PLACEHOLDER;
        }),
});

type ErrorType = { [path: string]: string[] };

const mapSchemaErrors = (e: ValidationError): ErrorType =>
    e.inner.reduce((errors: ErrorType, error: ValidationError): ErrorType => {
        if (error.path === undefined) {
            return errors;
        }
        const message = errors[error.path] || [];
        return {
            ...errors,
            [error.path]: message.concat(error.message),
        };
    }, {});

export const validate = (data: PersonalDetailsValidationData): Promise<TransformedPersonalDetailsValidationData> => {
    return validationSchema
        .validate(
            {
                dob: null,
                ...data,
            },
            { abortEarly: false },
        )
        .catch((e: ValidationError) => {
            throw mapSchemaErrors(e);
        });
};
