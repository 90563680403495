import { ApplicationError } from '../../../common/errors';

export class PasswordValidationErrors extends ApplicationError {
    public readonly validationErrors: string[] = [];

    public constructor(message: string, validationErrors: string[]) {
        super('PasswordValidationErrors', message);
        this.validationErrors = validationErrors;
    }
}

export const GENERIC_PASSWORD_ERROR =
    'The password you entered is not secure enough. Please enter a different password';

export class AccountAlreadyExistsError extends ApplicationError {
    public constructor() {
        super('AccountAlreadyExistsError', `A user with this username already exists in Ping`);
    }
}
