import React, { ReactElement, ReactNode, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { BackButton } from '@mo/back-button';
import { PageTitleWithTracking as Title } from '@mo/page-title';
import styles from './onlineAppsVerificationPage.module.scss';
import { RadioButtonGroup } from '@mo/radio-button-group';
import { LoadableButton } from '@mo/loadable-button';
import { EMAIL_ENTRY_PAGE } from '../../../common/routes';
import { Config } from '../../../domain/Config';
import appContext from '../../../common/appContext';
import verificationContext from '../verificationContext';

function SignIn({ goToSignIn, config }: { goToSignIn: () => void; config: Config }): ReactElement {
    return (
        <>
            <p className={styles.signInInstructions}>
                Please sign in with your existing username and password. If you have recently moved dealerships you can
                update your access when you log in.
            </p>
            <p className={styles.recoveryInstructions}>
                You can <a href={config.forgotUsernameUrl}>retrieve your username</a> or{' '}
                <a href={config.forgotPasswordUrl}>reset your password</a> if you're having trouble signing in.
            </p>

            <LoadableButton
                className={styles.signInButton}
                loadingText={`Sign in`}
                notLoadingText={`Sign in`}
                loading={false}
                onClick={(): void => goToSignIn()}
                role={'link'}
            />
        </>
    );
}

type stateRenderFn = (props: { goToSignIn: () => void; config: Config; continueHandler: () => void }) => ReactNode;

const uiStates: {
    true: stateRenderFn;
    false: stateRenderFn;
    notSelected: stateRenderFn;
    [state: string]: stateRenderFn;
} = {
    true: ({ goToSignIn, config }: { goToSignIn: () => void; config: Config }) => (
        <SignIn goToSignIn={goToSignIn} config={config} />
    ),
    false: ({ continueHandler }: { continueHandler: () => void }) => (
        <LoadableButton
            className={styles.continueButton}
            loadingText={``}
            notLoadingText={`Continue`}
            loading={false}
            onClick={continueHandler}
        />
    ),
    notSelected: () => <></>,
};

export function OnlineAppsVerificationPage(): ReactElement {
    const history = useHistory();
    const { config } = useContext(appContext);
    const { usedOlaBefore, setUsedOlaBefore } = useContext(verificationContext);

    const goToSignIn = (): void => {
        window.location.replace(config.signInUrl);
    };

    return (
        <div className={styles.gridContainer}>
            <div className={styles.page}>
                <div className={styles.content}>
                    <BackButton text={'Back to sign in'} onClick={(): void => goToSignIn()} role={'link'} />
                    <Title text={'Register to Online Applications'} className={styles.title} />
                    <RadioButtonGroup
                        values={[
                            { label: 'Yes', value: 'true' },
                            { label: 'No', value: 'false' },
                        ]}
                        setValue={setUsedOlaBefore}
                        selectedValue={usedOlaBefore}
                        label='Have you used Online Applications (also known as Motability Online) before?'
                        fieldName='usedBefore'
                    />
                    {uiStates[usedOlaBefore]({
                        continueHandler: (): void => history.push(EMAIL_ENTRY_PAGE),
                        goToSignIn,
                        config,
                    })}
                </div>
            </div>
        </div>
    );
}
