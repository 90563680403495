import React, { ReactElement } from 'react';
import styles from './invalidLinkPage.module.scss';
import { PageTitleWithTracking as Title } from '@mo/page-title';
import { Link } from 'react-router-dom';
import { HOME_PAGE } from '../../../common/routes';

export function InvalidLinkPage(): ReactElement {
    return (
        <div className={styles.gridContainer}>
            <div className={styles.page}>
                <div className={styles.form}>
                    <div className={styles.formHeader}>
                        <div>
                            <Title text={'This link is not valid'} />
                        </div>
                    </div>
                    <div className={styles.formBody}>
                        <p className={styles.instructionText}>Sorry, we didn't recognise this link.</p>
                        <p>Please use the button below to register to Online Applications.</p>
                        <Link className={styles.registerLink} to={HOME_PAGE}>
                            Register to Online Applications
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
