import { apiUrl } from '../../../common/gateway';
import { ServiceUnavailableError, SystemError } from '../../../common/errors';
import { DateType } from '../../../domain/Date';
import moment from 'moment';
import { AccountAlreadyExistsError, GENERIC_PASSWORD_ERROR, PasswordValidationErrors } from './errors';
import axios from 'axios';

export interface RegisterUserProfileRequest {
    token: string;
    firstName: string;
    lastName: string;
    password: string;
    dob: DateType;
    phoneNumber: string;
    jobTitle: string;
}

export interface RegisterUserProfileResponse {
    username: string;
    id: string;
    email: string;
}

export async function registerUserProfile({
    token,
    firstName,
    lastName,
    password,
    dob,
    phoneNumber,
    jobTitle,
}: RegisterUserProfileRequest): Promise<RegisterUserProfileResponse> {
    const body = {
        token,
        firstName,
        lastName,
        password,
        dob: formatDob(dob),
        phoneNumber,
        jobTitle,
    };

    return await throwingRegisterUserProfileErrors(
        (): Promise<RegisterUserProfileResponse> =>
            axios.post(apiUrl('register', 'v1'), body).then((response) => response.data),
    );
}

const formatDob = (dob: DateType): string => {
    return moment(`${dob.year}-${dob.month}-${dob.day}`, 'YYYY-MM-DD').format('DD/MMM/YYYY');
};

export async function throwingRegisterUserProfileErrors(
    fn: () => Promise<RegisterUserProfileResponse>,
): Promise<RegisterUserProfileResponse> {
    try {
        return await fn();
    } catch (err) {
        const error = err as unknown as { response: { status: number } };
        if (error.response.status === 400) {
            throw new PasswordValidationErrors('Password validation error', [GENERIC_PASSWORD_ERROR]);
        } else if (error.response.status === 409) {
            throw new AccountAlreadyExistsError();
        } else if (error.response.status === 503) {
            throw new ServiceUnavailableError('Recoverable error with registering user');
        }
        throw new SystemError(`${error.response.status}`);
    }
}
