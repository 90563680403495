import React, { FunctionComponent, ReactElement } from 'react';

import styles from './padlock.module.scss';

import padlock from './padlock.svg';

interface PadlockProps {
    className: string;
}

const Padlock: FunctionComponent<PadlockProps> = ({ className }: PadlockProps): ReactElement => {
    return (
        <div className={className}>
            <div className={styles.padlock}>
                <img src={padlock} alt={'Security is our priority'} aria-hidden='true' />
                <strong>Security is our priority</strong>
                <p className={styles.paragraph}>We take every precaution to look after your data.</p>
            </div>
        </div>
    );
};

export default Padlock;
