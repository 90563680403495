import React, { ChangeEvent, FunctionComponent, KeyboardEvent, ReactElement } from 'react';
import styles from './dateforminput.module.scss';
import { DateType } from '../../domain/Date';
import classnames from 'classnames';
import uniqueId from 'lodash.uniqueid';

export interface DateFormInputProps {
    labelText: string;
    exampleText?: string;
    hintText: string;
    date: DateType;
    onChange: (date: DateType) => void;
    errors?: string[];
    handleSubmit: () => void;
    highlightInlineError?: boolean;
}

const DateFormInput: FunctionComponent<DateFormInputProps> = (props: DateFormInputProps): ReactElement => {
    const id = uniqueId('dateFormGroupLabel-');
    const hintId = uniqueId('dateFormGroupHint-');

    const dateFormInputClasses = classnames(
        { [styles.errorHighlight]: props.errors || props.highlightInlineError },
        styles.dateFormInputGroup,
    );

    const update = (value: string, key: string): DateType => ({ ...props.date, [key]: value });

    const setAsDoubleDigit = (singleDigitEntry: string, dateTypeKey: string): void => {
        if (singleDigitEntry.length === 1) props.onChange(update('0' + singleDigitEntry, dateTypeKey));
    };

    const onKeyPress = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
            await props.handleSubmit();
        }
    };

    return (
        <div
            className={dateFormInputClasses}
            id='dob-wrapper'
            role='group'
            aria-labelledby={id}
            aria-describedby={hintId}
        >
            <label id={id} className={styles.dateFormInputGroupLabel} htmlFor={'dayField'}>
                {props.labelText}
            </label>
            {props.exampleText && <p className={styles.dateFormInputGroupExample}>{props.exampleText}</p>}
            <p id={hintId} className={styles.dateFormInputGroupHint}>
                {props.hintText}
            </p>
            <ul className={styles.inlineErrors}>
                {props.errors &&
                    props.errors.map((error: string): ReactElement => {
                        return (
                            <li key={error} data-locator='error-message' className={styles.inlineErrorMessage}>
                                {error}
                            </li>
                        );
                    })}
            </ul>
            <div className={styles.newForm}>
                <div className={styles.twoCharWidth}>
                    <label className={styles.formInputHint} htmlFor='dayField'>
                        Day
                    </label>
                    <input
                        className={classnames({ [styles.error]: props.errors })}
                        id='dayField'
                        aria-label='Please enter the day of your date of birth as one or two digits'
                        aria-describedby={hintId}
                        type='text'
                        inputMode='numeric'
                        pattern='[0-9]*'
                        autoComplete='bday-day'
                        name='dayField'
                        value={props.date.day}
                        maxLength={2}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                            props.onChange(update(event.currentTarget.value, 'day'))
                        }
                        onKeyPress={onKeyPress}
                        onBlur={(event): void => {
                            const newValue = event.currentTarget.value.replace('+', '');
                            props.onChange(update(newValue, 'day'));

                            setAsDoubleDigit(newValue, 'day');
                        }}
                    />
                </div>
                <div className={styles.twoCharWidth}>
                    <label className={styles.formInputHint} htmlFor='monthField'>
                        Month
                    </label>
                    <input
                        className={classnames({ [styles.error]: props.errors })}
                        id='monthField'
                        aria-label='Please enter the month of your date of birth as one or two digits'
                        type='text'
                        inputMode='numeric'
                        pattern='[0-9]*'
                        autoComplete='bday-month'
                        name='monthField'
                        value={props.date.month}
                        maxLength={2}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                            props.onChange(update(event.currentTarget.value, 'month'))
                        }
                        onKeyPress={onKeyPress}
                        onBlur={(event): void => {
                            const newValue = event.currentTarget.value.replace('+', '');
                            props.onChange(update(newValue, 'month'));

                            setAsDoubleDigit(newValue, 'month');
                        }}
                    />
                </div>
                <div className={styles.fourCharWidth}>
                    <label className={styles.formInputHint} htmlFor='yearField'>
                        Year
                    </label>
                    <input
                        className={classnames({ [styles.error]: props.errors })}
                        id='yearField'
                        aria-label='Please enter the year of your date of birth as four digits'
                        type='text'
                        inputMode='numeric'
                        pattern='[0-9]*'
                        autoComplete='bday-year'
                        name='yearField'
                        value={props.date.year}
                        maxLength={4}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                            props.onChange(update(event.currentTarget.value, 'year'))
                        }
                        onKeyPress={onKeyPress}
                        onBlur={(event): void => {
                            const newValue = event.currentTarget.value.replace('+', '');
                            props.onChange(update(newValue, 'year'));
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateFormInput;
