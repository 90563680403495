import { apiUrl } from '../../../common/gateway';
import axios from 'axios';

export interface InitiateRegistrationRequest {
    email: string;
}

export async function initiateRegistration({
    email,
}: InitiateRegistrationRequest): Promise<InitiateRegistrationRequest> {
    const body = {
        email,
    };

    return await axios.post(apiUrl('initiate', 'v1'), body).then((response) => response.data);
}
