import { createContext } from 'react';

export interface VerificationContextProps {
    email: string;
    setEmail: (email: string) => void;
    usedOlaBefore: string;
    setUsedOlaBefore: (usedOlaBefore: string) => void;
}

const registrationContext = createContext<VerificationContextProps>({
    email: '',
    setEmail: () => {
        //do nothing
    },
    usedOlaBefore: '',
    setUsedOlaBefore: () => {
        //do nothing
    },
});

export const initialUsedOlaBefore = 'notSelected';

export default registrationContext;
