import React, { FunctionComponent, ReactElement } from 'react';
import * as serviceWorker from './common/serviceWorker';
import 'normalize.css';
import './reset.local.scss';
import './index.scss';
import AppContainer from './AppContainer';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
        },
    },
});

const Index: FunctionComponent = (): ReactElement => {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AppContainer />
            </QueryClientProvider>
        </BrowserRouter>
    );
};

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<Index />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
