import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import {
    ACCOUNT_ALREADY_EXISTS_PAGE,
    LINK_INVALID_PAGE,
    PASSWORD_PAGE,
    PERSONAL_DETAILS_PAGE,
    SUCCESS_PAGE,
} from '../../common/routes';
import RegistrationContext from './registrationContext';
import { PersonalDetailsPage } from './personalDetails/PersonalDetailsPage';
import CreatePasswordPage from './password/CreatePasswordPage';
import SuccessPage from './success/SuccessPage';
import AccountAlreadyExistsPage from './accountAlreadyExists/AccountAlreadyExistsPage';
import { blankPersonalDetails, PersonalDetails } from '../../domain/PersonalDetails';
import { blankUserInfo, UserInfo } from '../../domain/UserInfo';
import { InvalidLinkPage } from './invalidLink/InvalidLinkPage';
import { useQueryParam } from '@mo/hooks';

function RegistrationRouter(): ReactElement {
    const { token: tokenParam } = useQueryParam(window.location);
    const history = useHistory();
    const [token, setToken] = useState<string>('');
    const [personalDetails, setPersonalDetails] = useState<PersonalDetails>(blankPersonalDetails);
    const [userInfo, setUserInfo] = useState<UserInfo>(blankUserInfo);

    useEffect(() => {
        if (!token) {
            setToken(tokenParam || '');

            history.replace({
                pathname: tokenParam ? PERSONAL_DETAILS_PAGE : LINK_INVALID_PAGE,
            });
        }
    }, [history, token, tokenParam]);

    const value = { token, personalDetails, setPersonalDetails, userInfo, setUserInfo };

    return (
        <RegistrationContext.Provider value={value}>
            <Switch>
                <Route exact path={PERSONAL_DETAILS_PAGE} component={PersonalDetailsPage} />
                <Route exact path={PASSWORD_PAGE} render={(): ReactNode => <CreatePasswordPage />} />
                <Route exact path={SUCCESS_PAGE} render={(): ReactNode => <SuccessPage />} />
                <Route exact path={LINK_INVALID_PAGE} render={(): ReactNode => <InvalidLinkPage />} />
                <Route
                    exact
                    path={ACCOUNT_ALREADY_EXISTS_PAGE}
                    render={(): ReactNode => <AccountAlreadyExistsPage />}
                />
            </Switch>
        </RegistrationContext.Provider>
    );
}

export default RegistrationRouter;
