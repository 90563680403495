export type Config = {
    readonly signInUrl: string;
    readonly forgotPasswordUrl: string;
    readonly forgotUsernameUrl: string;
};

export const blankConfig: Config = {
    signInUrl: '',
    forgotPasswordUrl: '',
    forgotUsernameUrl: '',
};
