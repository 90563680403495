import React, { FunctionComponent, ReactElement, useContext } from 'react';
import styles from './accountAlreadyExistsPage.module.scss';
import { PageTitleWithTracking as Title } from '@mo/page-title';
import appContext from '../../../common/appContext';

const AccountAlreadyExistsPage: FunctionComponent = (): ReactElement => {
    const { config } = useContext(appContext);

    return (
        <div className={styles.gridContainer}>
            <div className={styles.page}>
                <div className={styles.form}>
                    <div className={styles.formHeader}>
                        <div>
                            <Title text={'It looks like you already have an Online Applications account'} />
                        </div>
                    </div>
                    <div className={styles.formBody}>
                        <p className={styles.instructionText}>
                            Please sign in with your existing username and password. You can{' '}
                            <a href={config.forgotUsernameUrl}>retrieve your username</a> or{' '}
                            <a href={config.forgotPasswordUrl}>reset your password</a> if you're having trouble signing
                            in.
                        </p>
                        <p>
                            Please contact the Dealer Contact Services team on{' '}
                            <a href={'tel:0300 037 0200'}>0300 037 0200</a> if you need any help.
                        </p>
                        <a className={styles.signInLink} href={config.signInUrl}>
                            Sign in
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountAlreadyExistsPage;
