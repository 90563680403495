import React, { ReactElement, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { EMAIL_CONFIRMATION_PAGE, EMAIL_ENTRY_PAGE, HOME_PAGE } from '../../common/routes';
import VerificationContext, { initialUsedOlaBefore } from './verificationContext';
import { EmailEntryPage } from './emailEntry/EmailEntryPage';
import { EmailConfirmationPage } from './emailConfirmation/EmailConfirmationPage';
import { OnlineAppsVerificationPage } from './onlineAppsVerification/OnlineAppsVerificationPage';

function VerificationRouter(): ReactElement {
    const [usedOlaBefore, setUsedOlaBefore] = useState<string>(initialUsedOlaBefore);
    const [email, setEmail] = useState<string>('');

    return (
        <VerificationContext.Provider value={{ email, setEmail, usedOlaBefore, setUsedOlaBefore }}>
            <Switch>
                <Route exact path={HOME_PAGE} component={OnlineAppsVerificationPage} />
                <Route exact path={EMAIL_ENTRY_PAGE} component={EmailEntryPage} />
                <Route exact path={EMAIL_CONFIRMATION_PAGE} component={EmailConfirmationPage} />
            </Switch>
        </VerificationContext.Provider>
    );
}

export default VerificationRouter;
