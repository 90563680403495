import { DateType } from './Date';

export const JOB_TITLE_PLACEHOLDER = 'Select a job title...';

export type PersonalDetails = {
    readonly firstName: string;
    readonly lastName: string;
    readonly dob: DateType;
    readonly phoneNumber: string;
    readonly jobTitle: string;
};

export const blankPersonalDetails: PersonalDetails = {
    firstName: '',
    lastName: '',
    dob: {},
    phoneNumber: '',
    jobTitle: JOB_TITLE_PLACEHOLDER,
};
