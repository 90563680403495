export const INVALID_OR_BLANK_YEAR = 'year';
export const INVALID_OR_BLANK_MONTH = 'month';
export const INVALID_OR_BLANK_DAY = 'day';
export const INVALID_DOB = 'Please enter a valid date of birth';

export const INVALID_MOBILE_PHONE = 'Please enter a valid mobile phone number';
export const INVALID_FIRST_NAME = 'Please enter a valid first name';
export const INVALID_LAST_NAME = 'Please enter a valid last name';

export const INVALID_JOB_TITLE = 'Please select a job title';
export const TC_NOT_ACCEPTED = 'Please accept the terms and conditions';
