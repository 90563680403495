import { createContext } from 'react';
import { blankPersonalDetails, PersonalDetails } from '../../domain/PersonalDetails';
import { blankUserInfo, UserInfo } from '../../domain/UserInfo';

export interface RegistrationContextProps {
    token: string;
    setPersonalDetails: (personalDetails: PersonalDetails) => void;
    personalDetails: PersonalDetails;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
}

const registrationContext = createContext<RegistrationContextProps>({
    token: '',
    personalDetails: blankPersonalDetails,
    setPersonalDetails: () => {
        //do nothing
    },
    userInfo: blankUserInfo,
    setUserInfo: () => {
        //do nothing
    },
});

export default registrationContext;
