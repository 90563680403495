import React, { FunctionComponent, ReactElement, useContext } from 'react';
import styles from './successPage.module.scss';
import { PageTitleWithTracking as Title } from '@mo/page-title';
import appContext from '../../../common/appContext';
import registrationContext from '../registrationContext';

const SuccessPage: FunctionComponent = (): ReactElement => {
    const { config } = useContext(appContext);
    const { userInfo } = useContext(registrationContext);

    const { username, email } = userInfo;

    return (
        <div className={styles.gridContainer}>
            <div className={styles.page}>
                <div className={styles.form}>
                    <div className={styles.formHeader}>
                        <div>
                            <Title text={'Your Online Applications account has been created'} />
                        </div>
                    </div>
                    <div className={styles.formBody}>
                        <div className={styles.usernameCard}>
                            <p>Your username is:</p>
                            <p className={styles.usernameContainer}>
                                <strong className={styles.username}>{username}</strong>
                            </p>
                            <p>This will be required to sign in</p>
                        </div>
                        <p className={styles.instructionText}>
                            Your username has also been sent to <strong className={styles.email}>{email}</strong>
                        </p>
                        <p>Please sign in to request access to your current dealership(s).</p>
                        <a className={styles.signInLink} href={config.signInUrl}>
                            Sign in
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessPage;
